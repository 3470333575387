<template>
  <div class="withdraw">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>提现</span>
      <span></span>
    </header>
    <main class="page-body">
      <van-form @submit="onSubmit">
        <van-field :value="0" label="可提总额" readonly></van-field>
        <van-field
          v-model="amount"
          label="金额"
          placeholder="最低提现金额100元，最高10000元"
          :rules="[{ required: true, message: '请填写金额' }]"
        />
        <van-field v-model="desc" label="备注" placeholder="备注信息" />
        <van-field
          v-model="password"
          label="交易密码"
          placeholder="交易密码"
          type="password"
          :rules="[{ required: true, message: '请填写交易密码' }]"
        />
        <div class="btn-block">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
      <div class="note">
        只有可用余额才能提现,提现申请之后3个工作日内,我们工作人员将给您绑定的银行卡支付提现,
        您可以进入
        <router-link :to="{ name: 'ApplicationList' }" class="theme-color">
          [充提申请] </router-link
        >里面查看详情
      </div>
    </main>
  </div>
</template>
<script>
import { submitWithdraw } from '@/api/money.js';
export default {
  data() {
    return {
      amount: '',
      password: '',
      desc: '',
    };
  },
  methods: {
    onSubmit() {
      this.$toast.loading({ duration: 0 });
      submitWithdraw({
        amount: this.amount,
        password: this.password,
        desc: this.desc,
      })
        .then(res => {
          this.$toast.clear();
          if (res.Result) {
            this.$toast.success(res.Info);
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          } else {
            this.$toast.fail(res.Info);
          }
        })
        .catch(() => {
          this.$toast.clear();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.btn-block {
  padding: rem(20px);
}
.van-form {
  margin-top: rem(20px);
}
.note {
  padding: rem(20px);
  color: #666;
}
</style>
