const qs = require('querystring');
import API from './index';

// order=asc&offset=0&limit=15&state=-2&start-date=2020-01-29&end-date=2020-12-29&input-search=&_next_pn=0.07281672199904143

export function getTransactions({
  startDate,
  endDate,
  keyword,
  limit,
  offset,
  order,
} = {}) {
  return API.get('/fin/QueryCashFlow', {
    params: {
      'start-date': startDate,
      'end-date': endDate,
      'input-search': keyword,
      limit,
      offset,
      order,
    },
  }).then(res => res.data);
}

export function getApplicationList({
  startDate,
  endDate,
  keyword,
  limit,
  offset,
  order,
} = {}) {
  return API.get('/fin/QueryFinReq', {
    params: {
      'start-date': startDate,
      'end-date': endDate,
      'input-search': keyword,
      limit,
      offset,
      order,
    },
  }).then(res => res.data);
}

// txt-wamount=111&
// txt-desc=test&
// txt-wpwd=1111
export function submitWithdraw({ amount, password, desc } = {}) {
  return API.post(
    '/fin/DoWithdraw',
    qs.stringify({
      'txt-wamount': amount,
      'txt-desc': desc,
      'txt-wpwd': password,
    })
  ).then(res => res.data);
}

/**
 * 充值
 */
export function submitCharge({ amount, desc, env, cb } = {}) {
  return API.post(
    '/fin/VueWeixinRecharge',
    qs.stringify({
      amount: amount,
      desc: desc,
      env: env,
      cb: cb,
    })
  ).then(res => res.data);
}

/**
 * 充值回调
 */
export function chargeResult({ chargeNo } = {}) {
  return API.post(
    '/fin/VueQueryCharge',
    qs.stringify({
      chargeNo: chargeNo,
    })
  ).then(res => res.data);
}

export function modifyPaypwd({ validateCode, newPwd, imgCode } = {}) {
  return API.get('/fin/modifyPaypwd', {
    params: {
      validateNum: validateCode,
      fx_pwd: newPwd,
      imgCode: imgCode,
    },
  }).then(res => res.data);
}
